import React, { FC } from 'react';
import { Box, Button, Typography } from '@mui/material';
import { useSrcImage } from 'hooks';
import { useAppDispatch, useAppSelector } from 'store/hooks/useAppSelector';
import { selectMenuProduct } from 'store/selectors/menuSelectors';
import { orderSlice } from 'store/reducers/OrderSlice/OrderSlice';
import { useTranslation } from 'react-i18next';

interface ProductProps {
  itemId: number;
  categoryId: number;
  itemOption: number;
  onClose: () => void;
  actionId: number;
}

const Product: FC<ProductProps> = ({
  itemId,
  categoryId,
  itemOption,
  onClose,
  actionId,
}) => {
  const dispatch = useAppDispatch();
  const { addPromotion } = orderSlice.actions;
  const productData = useAppSelector(selectMenuProduct(itemId, categoryId));
  const image = useSrcImage(productData.src);
  const { t } = useTranslation();

  const handleSelectProduct = () => {
    dispatch(addPromotion({ action_id: actionId, item_option_id: itemOption }));
    onClose();
  };

  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        gap: '20px',
        flexDirection: 'column',
      }}
    >
      <Box
        sx={{
          height: 'min(150px, 100%)',
          aspectRatio: '1 / 1',
        }}
        component="img"
        src={image}
      />
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: '10px',
          flexGrow: '1',
        }}
      >
        <Box>
          <Typography
            sx={{
              fontWeight: '400',
              fontSize: { xs: '15px', sm: '20px' },
              lineHeight: { xs: '17px', sm: '23px' },
              marginBottom: '5px',
            }}
            variant="h6"
            color="text.main"
          >
            {productData.name}
          </Typography>
          <Typography
            sx={{
              fontWeight: '300',
              fontSize: { xs: '12px', sm: '14px' },
              lineHeight: { xs: '15px', sm: '18px' },
            }}
            color="grey.50"
          >
            {productData.description}
          </Typography>
        </Box>

        <Button
          sx={{
            width: '150px',
            height: '45px',
            textTransform: 'inherit',
            fontSize: '14px',
            lineHeight: '16px',
            borderRadius: '6px',
            fontWeight: '300',
            color: 'secondary.main',
            boxShadow: 'none',
            alignSelf: 'flex-end',
          }}
          variant="contained"
          onClick={handleSelectProduct}
        >
          {t('add_to_cart')}
        </Button>
      </Box>
    </Box>
  );
};

export default Product;
